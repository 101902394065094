<ul class="list_menu">
  <li>
    <a class="link_lnb" [routerLink]="['/data-management']" routerLinkActive="lnb_link_open"
      ><!-- 클릭시 lnb_link_open 클래스 추가(2depth open) -->
      <span class="txt_menu">Data Management</span>
    </a>
  </li>
  <li>
    <a class="link_lnb" [routerLink]="['/ai-scanning']" routerLinkActive="lnb_link_open"
      ><!-- 클릭시 lnb_link_open 클래스 추가(2depth open) -->
      <span class="txt_menu">AI Scanning</span>
    </a>
  </li>
  <!-- <li>
    <a class="link_lnb" [routerLink]="['/export-excel']" routerLinkActive="lnb_link_open"
      >
      <span class="txt_menu">Export to Excel</span>
    </a>
  </li> -->
  <li>
    <a class="link_lnb" [routerLink]="['/cargo-tracking']" routerLinkActive="lnb_link_open">
      <span class="txt_menu">Cargo Tracking</span>
    </a>
  </li>
  <li>
    <a class="link_lnb" [routerLink]="['/warehouse-management']" routerLinkActive="lnb_link_open">
      <span class="txt_menu">Warehouse</span>
    </a>
    <!-- <ul class="list_menu_depth">
      <li>
        <a>Inbound / Outbound</a>
      </li>
      <li>
        <a>Inventory Status</a>
      </li>
    </ul> -->
  </li>
  <li>
    <a class="link_lnb" [routerLink]="['/inventory-management']" routerLinkActive="lnb_link_open">
      <span class="txt_menu">Inventory</span>
    </a>
  </li>
  <li>
    <a class="link_lnb" [routerLink]="['/link-editor']" routerLinkActive="lnb_link_open"
      ><!-- 클릭시 lnb_link_open 클래스 추가(2depth open) -->
      <span class="txt_menu">Document Link Editor</span>
    </a>
  </li>

  <li>
    <a class="link_lnb" [routerLink]="['/freetime/management']" routerLinkActive="lnb_link_open">
      <span class="txt_menu">Freetime Management</span>
    </a>
  </li>

  <!-- <li>
    <a class="link_lnb" [routerLink]="['/template-setting/:templateSeq']" routerLinkActive="lnb_link_open">
      <span class="txt_menu">Data Settings</span>
    </a>
    <ul class="list_menu_depth">
      <li>
        <a>Document Link</a>
      </li>
      <li>
        <a>Freetime Mng</a>
      </li>
    </ul>
  </li> -->
</ul>
