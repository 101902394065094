import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from '@env/environment';

/*
if (environment.production && environment.domain === 'wiseconvey') {
  Sentry.init({
    dsn: environment.sentryKey,
    maxBreadcrumbs: 20,
    // debug: true,
    attachStacktrace: true,
    autoSessionTracking: true,
    initialScope: {
      tags: { client: 'wiseconvey-v3' },
    },
    integrations: [
      // // Registers and configures the Tracing integration,
      // // which automatically instruments your application to monitor its
      // // performance, including custom Angular routing instrumentation
      // Sentry.browserTracingIntegration(),
      // // Registers the Replay integration,
      // // which automatically captures Session Replays
      // Sentry.replayIntegration(),
      Sentry.breadcrumbsIntegration({
        console: false,
      }),
    ],

    // // Set tracesSampleRate to 1.0 to capture 100%
    // // of transactions for performance monitoring.
    // // We recommend adjusting this value in production
    // tracesSampleRate: 0.3,

    // // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^https:\/\/api\.wiseconvey\.com\//],

    // // Capture Replay for 10% of all sessions,
    // // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  });
}
*/

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
